import {
  Button,
  LoadPanel,
  SelectBox,
  TagBox,
  TextBox,
  Validator,
} from "devextreme-react";
import { EmailRule } from "devextreme-react/validator";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useAuth0 } from "src/react-auth0-spa";

interface Role {
  id: string;
  name: string;
  description: string;
}

interface Metadata {
  OrganizationID: string;
  TenantID: string;
}

interface Organization {
  id: string;
  name: string;
  display_name: string;
  metadata: Metadata;
}

interface Member {
  user_id: string;
  name: string;
  email: string;
}

export const TelemetryRoles = () => {
  const { fetchNoCheck } = useAuth0();
  const [token, setToken] = useState("");
  const [rolesForMember, setRolesForMember] = useState<Role[]>([]);
  const [origRolesForMember, setOrigRolesForMember] = useState<Role[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [members, setMembers] = useState<Member[]>([]);

  const [selectedOrg, setSelectedOrg] = useState<Organization>(null);
  const [selectedMember, setSelectedMember] = useState<Member>(null);
  const [groupedRoles, setGroupedRoles] = useState([]);

  const [somethingChanged, setSomethingChanged] = useState(false);
  const [busy, setBusy] = useState(true);

  const [error, setError] = useState("");

  const validatorRef = useRef();

  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchNoCheck("/auth0Token")
      .then((resp) => {
        setToken(resp.access_token);
      })
      .catch((error) => {
        console.error(error);
        setError(`${error}`);
      })
      .finally(() => setBusy(false));
  }, [fetchNoCheck]);

  useEffect(() => {
    if (token) {
      setBusy(true);
      fetchNoCheck("/auth0Roles", {
        method: "POST",
        body: JSON.stringify({ token }),
      }).then((resp: Role[]) => {
        const justTelemetry = resp.filter((r) => r.name.includes("Telemetry"));
        const grouped = [
          {
            key: "Standard",
            items: justTelemetry.filter((r) => !r.name.includes("- Dist")),
          },
          {
            key: "Distributors",
            items: justTelemetry.filter((r) => r.name.includes("- Dist")),
          },
        ];
        setGroupedRoles(grouped);
      });

      fetchNoCheck("/auth0Organizations", {
        method: "POST",
        body: JSON.stringify({ token }),
      }).then((resp: Organization[]) => {
        setBusy(false);
        setOrganizations(resp);
        setSelectedOrg(null);
        setSelectedMember(null);
        setRolesForMember([]);
        setOrigRolesForMember([]);
        setSomethingChanged(false);
      });
    }
  }, [token, fetchNoCheck]);

  useEffect(() => {
    if (selectedOrg) {
      if (selectedOrg.id) {
        setBusy(true);
        fetchNoCheck("/auth0Members", {
          method: "POST",
          body: JSON.stringify({ token, orgID: selectedOrg.id }),
        }).then((resp: Member[]) => {
          setMembers(resp);
          setSelectedMember(null);
          setRolesForMember([]);
          setOrigRolesForMember([]);
          setSomethingChanged(false);
          setBusy(false);
        });
      }
    }
  }, [selectedOrg, fetchNoCheck, token]);

  useEffect(() => {
    if (groupedRoles && selectedOrg && selectedMember) {
      if (selectedOrg.id && selectedMember.user_id) {
        fetchNoCheck("/auth0RolesForMember", {
          method: "POST",
          body: JSON.stringify({
            token,
            orgID: selectedOrg.id,
            userID: selectedMember.user_id,
          }),
        }).then((resp: Role[]) => {
          const roles = resp
            .filter((v) => v.name.includes("Telemetry"))
            .map((v) => {
              for (let i = 0; i < groupedRoles.length; i++) {
                for (let j = 0; j < groupedRoles[i].items.length; j++) {
                  if (groupedRoles[i].items[j].id === v.id)
                    return groupedRoles[i].items[j];
                }
              }
              return null;
            });
          setRolesForMember(roles);
          setOrigRolesForMember(roles);
          setSomethingChanged(false);
        });
      }
    }
  }, [selectedOrg, selectedMember, fetchNoCheck, groupedRoles, token]);

  const getRolesToDelete = useCallback(() => {
    const roleIDs = [];
    for (let i = 0; i < origRolesForMember.length; i++) {
      if (!rolesForMember.find((v) => v.id === origRolesForMember[i].id)) {
        roleIDs.push(origRolesForMember[i].id);
      }
    }
    return roleIDs;
  }, [origRolesForMember, rolesForMember]);

  const getRolesToAdd = useCallback(() => {
    const roleIDs = [];
    for (let i = 0; i < rolesForMember.length; i++) {
      if (!origRolesForMember.find((v) => v.id === rolesForMember[i].id)) {
        roleIDs.push(rolesForMember[i].id);
      }
    }
    return roleIDs;
  }, [origRolesForMember, rolesForMember]);

  const saveChanges = useCallback(() => {
    setBusy(true);
    const rolesToAdd = getRolesToAdd();
    const rolesToDelete = getRolesToDelete();
    fetchNoCheck("/auth0SaveRolesForMember", {
      method: "POST",
      body: JSON.stringify({
        token,
        orgID: selectedOrg.id,
        userID: selectedMember.user_id,
        rolesToAdd,
        rolesToDelete,
      }),
    })
      .then(() => {
        setSomethingChanged(false);
        setOrigRolesForMember(rolesForMember);
        setBusy(false);
      })
      .catch(() => {
        setBusy(false);
      });
  }, [
    getRolesToAdd,
    getRolesToDelete,
    fetchNoCheck,
    selectedMember,
    rolesForMember,
    selectedOrg,
    token,
  ]);

  const inviteUser = useCallback(() => {
    setBusy(true);
    const roles = getRolesToAdd();
    fetchNoCheck("/auth0SendInvite", {
      method: "POST",
      body: JSON.stringify({
        token,
        orgID: selectedOrg.id,
        email,
        roles,
      }),
    })
      .then(() => {
        setSomethingChanged(false);
        setBusy(false);
        alert("Invite sent!");
      })
      .catch((error) => {
        setBusy(false);
        alert(`${error}`);
      });
  }, [fetchNoCheck, email, selectedOrg, token, getRolesToAdd]);

  if (error) return <div style={{ marginTop: 200, color: "red" }}>{error}</div>;

  return (
    <div id="parentDiv">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: "#parentDiv" }}
        visible={busy}
        showIndicator={true}
        shading={true}
        // showPane={true}
      />
      <div
        style={{
          marginTop: 30,
          width: 400,
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          gap: 20,
        }}
      >
        <div style={{ width: 100 }}>Organization:</div>
        <SelectBox
          dataSource={organizations}
          searchEnabled={true}
          value={selectedOrg}
          onValueChanged={(e) => setSelectedOrg(e.value)}
          itemRender={(data: Organization) => (
            <span>
              {data.name} ({data.display_name})
            </span>
          )}
          displayExpr="display_name"
          style={{ flexGrow: 1 }}
        />
      </div>
      <div
        style={{
          width: 400,
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          gap: 20,
          marginTop: 10,
        }}
      >
        <div style={{ width: 100 }}>User:</div>
        <SelectBox
          dataSource={members}
          searchEnabled={true}
          value={selectedMember}
          onValueChanged={(e) => {
            setSelectedMember(e.value);
            setEmail("");
          }}
          showClearButton
          itemRender={(data: Member) => (
            <span>
              {data.name} ({data.email})
            </span>
          )}
          displayExpr="name"
          style={{ flexGrow: 1 }}
        />
      </div>
      <div
        style={{
          width: 600,
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          gap: 20,
          marginTop: 20,
        }}
      >
        <div style={{ width: 350 }}>
          Or, if the user you would like to assign roles to doesn't exist, enter
          their email, set roles, and click "send invite":
        </div>
        <TextBox
          disabled={!!selectedMember || !selectedOrg}
          placeholder="email address"
          style={{ flexGrow: 1 }}
          value={email}
          onValueChanged={(v) => setEmail(v.value)}
        >
          <Validator ref={validatorRef} name="Email">
            <EmailRule ignoreEmptyValue />
          </Validator>
        </TextBox>
      </div>
      <hr style={{ marginTop: 30 }} />
      <TagBox
        width={600}
        style={{ background: "rgb(240,240,240)", padding: 10 }}
        dataSource={groupedRoles}
        value={rolesForMember}
        grouped={true}
        displayExpr="name"
        onValueChanged={(v) => {
          setRolesForMember(v.value);
          setSomethingChanged(true);
        }}
      />
      <br />
      <br />
      <Button
        text="Save Changes"
        disabled={!somethingChanged || !selectedMember}
        onClick={() => {
          saveChanges();
        }}
      />
      &nbsp; &nbsp;
      <Button
        text="Revert"
        disabled={!somethingChanged}
        onClick={() => {
          setRolesForMember(origRolesForMember);
          setSomethingChanged(false);
        }}
      />
      &nbsp; &nbsp;
      <Button
        width="120px"
        text="Send Invite"
        disabled={
          !selectedOrg || !!selectedMember || email === "" || !somethingChanged
        }
        onClick={() => {
          const result =
            validatorRef &&
            validatorRef?.current &&
            (validatorRef.current as any).instance.validate();
          if (result && (result as any).isValid) {
            inviteUser();
          }
        }}
      />
    </div>
  );
};
